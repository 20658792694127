import { useEffect } from 'react'

/**
 * Hook that fire callback when clicks outside of the passed ref
 */
export const useOutsideClick = (ref: HTMLElement, callback?: () => void) => {
  useEffect(() => {
    /**
     * Fire callback if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref && !ref.contains(event.target)) {
        // Unbind the event listener
        document.removeEventListener('mousedown', handleClickOutside)
        callback && callback()
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [callback, ref])
}
