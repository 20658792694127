import styled from 'styled-components'
import tw from 'twin.macro'

import Input from 'components/Input'

export const InputItem = tw(Input)`
    pr-7
    border-bottom-left-radius[0px]
    border-bottom-right-radius[0px]
    border-0
    hover:border-0
    focus:border-0
`

export const DescriptionIconLabel = tw.div`flex items-center`

export const IconContainer = styled.span`
  ${tw`min-w-[25px] mr-2`}
  svg {
    ${tw`w-[25px] h-[25px] fill-white`}
  }
`
