import styled from 'styled-components'
import tw from 'twin.macro'

export interface ListProps {
  disabled?: boolean
}

export const List = styled.ul<ListProps>`
  ${tw`
    bg-light-700
    border
    border-solid
    border-light-700
    border-radius[4px]
    overflow-x-hidden
    max-h-[175px]
  `}

  & > li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0;

    &:first-of-type {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }

    &:last-of-type {
      border-bottom: none;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
`
