import tw from 'twin.macro'

import Button from 'components/Button'

export const Wrapper = tw.div`
    flex
    justify-between
    items-center
`

export const CurrencyPairToolbar = tw.div`flex gap-[4px] items-center`

export const CurrencyPairDivider = tw.div`
    w-[1px]
    h-[19px]
    bg-light-700
    transform[rotate(11.89deg)]
`

export const CurrencySelectors = tw.span`
    text-light-300
    font-ringside-narrow
    font-weight[300]
    text-[12px]
    leading-[14px]
    text-center
    flex
    items-center
    gap-[3px]
    cursor-pointer
`

export const CurrencyButton = tw(Button)`
    p-1
    text-light-300
    text-[12px]
    leading-[16px]
`

export const CurrencyBaseIconSpan = tw.span`mr-1`
