import styled from 'styled-components'
import tw from 'twin.macro'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  sizeInput?: 'default' | 'large'
  error?: boolean
  hiddenStyle?: boolean
}

export const Input = styled.input<InputProps>`
  ${tw`
    h-[32px]
    p-2
    border-radius[4px]
    border
    border-color[rgba(0, 0, 0, 0.3)]
    bg-[rgba(255, 255, 255, 0.05)]
    font-ringside-narrow
    font-weight[300]
    text-[12px]
    leading-[14px]
    text-white
    outline-none
    placeholder:text-light-500
  `}

  ${tw`
    hover:bg-[rgba(255, 255, 255, 0.05)]
    hover:border-color[rgba(255, 255, 255, 0.2)]
  `}

  ${tw`
    focus:bg-[rgba(255, 255, 255, 0.03)]
    focus:text-white
    focus:border
    focus:border-color[rgba(255, 255, 255, 0.4)]
  `}

  ${({ sizeInput }) =>
    sizeInput === 'large' ? tw`h-[38px] text-[16px] font-weight[350]` : null}
  
  ${({ error }) =>
    error ? tw`border-ui-red hover:border-ui-red focus:border-ui-red` : null}

  ${({ hiddenStyle }) =>
    hiddenStyle
      ? tw`
          border-transparent
          hover:border-transparent
          focus:border-transparent
          bg-transparent
          hover:bg-transparent
          focus:bg-transparent
        `
      : null}

  ${({ disabled }) =>
    disabled
      ? tw`
          bg-[rgba(255, 255, 255, 0.02)]
          hover:bg-[rgba(255, 255, 255, 0.02)]
          border-transparent
          hover:border-transparent
          opacity-50
        `
      : null}
`
