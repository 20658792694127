import { useState } from 'react'
import ReactDOM from 'react-dom'
import { usePopper } from 'react-popper'

import useOutsideClick from 'hooks/useOutsideClick'
import 'twin.macro'

export interface PopoverProps {
  'data-test': string
  referenceElement: HTMLElement | null
  placement:
    | 'top'
    | 'bottom'
    | 'right'
    | 'left'
    | 'top-start'
    | 'top-end'
    | 'bottom-start'
    | 'bottom-end'
    | 'right-start'
    | 'right-end'
    | 'left-start'
    | 'left-end'
    | 'auto'
    | 'auto-start'
    | 'auto-end'
  distance: number //px
  children?: React.ReactNode
  onDismiss?: () => void
}

export const Popover: React.FC<PopoverProps> = ({
  'data-test': dataTest = 'popover',
  referenceElement,
  placement,
  distance,
  children,
  onDismiss,
}) => {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  )

  useOutsideClick(popperElement, onDismiss)

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement,
    // add px of distance from distance prop
    modifiers: [{ name: 'offset', options: { offset: [0, distance] } }],
  })

  return ReactDOM.createPortal(
    <div
      data-test={`${dataTest}.container`}
      ref={setPopperElement}
      style={styles.popper}
      {...attributes.popper}
      tw="z-[1000]"
    >
      {children}
    </div>,
    document.body,
  )
}
