import React from 'react'
import 'twin.macro'

import Search from 'assets/icons/search.svg'
import List from 'components/List'
import ListItem from 'components/ListItem'
import useSecurityCurrencies from 'hooks/useSecurityCurrencies'
import { getCurrencyIconBySymbol } from 'lib/app-util'
import { Currency } from 'types/currency'

import {
  DescriptionIconLabel,
  IconContainer,
  InputItem,
} from './CurrencySelect.styles'

export interface CurrencySelectProps {
  'data-test': string
  baseCurrency?: Currency // this allow to shows the quoted currencies based on the base currency
  onSelectValue: (e: { value: Currency }) => void
}

export const CurrencySelect: React.FC<CurrencySelectProps> = ({
  'data-test': dataTest = 'currency-select',
  baseCurrency,
  onSelectValue,
}) => {
  const { filteredCurrencies, searchCurrency } =
    useSecurityCurrencies(baseCurrency)

  // Event handler for keydowns
  const handleKeyDown = (value: Currency) => (e) => {
    switch (e.key) {
      case ' ':
      case 'SpaceBar':
      case 'Enter':
        e.preventDefault()
        onSelectValue({ value: value })
        break
      default:
        break
    }
  }

  const Item = (currency: Currency) => {
    const icon = getCurrencyIconBySymbol(currency.Symbol)
    return (
      <ListItem
        data-test={`${dataTest}.item`}
        data-test-arg={currency.Symbol}
        id={currency.Symbol}
        role="option"
        tabIndex={0}
        key={currency.Symbol}
        onKeyDown={handleKeyDown(currency)}
        onClick={() => onSelectValue({ value: currency })}
      >
        <DescriptionIconLabel>
          <IconContainer data-test={`${dataTest}.item-icon`}>
            {icon ? icon() : null}
          </IconContainer>
          <span data-test={`${dataTest}.item-description`}>
            {currency.Description}
          </span>
        </DescriptionIconLabel>

        <span data-test={`${dataTest}.item-symbol`} tw="opacity-50">
          {currency.Symbol}
        </span>
      </ListItem>
    )
  }

  const NotFoundItem = (
    <ListItem
      data-test={`${dataTest}.not-found`}
      role="option"
      tabIndex={0}
      disabled
    >
      Currencies not found
    </ListItem>
  )

  const ListItems = () => {
    return filteredCurrencies.length > 0
      ? filteredCurrencies.map((item) => Item(item))
      : NotFoundItem
  }

  return (
    <div data-test={`${dataTest}.container`} tw="relative max-w-min">
      <List role="listbox" tabIndex={-1}>
        <li tw="relative">
          <InputItem
            data-test={`${dataTest}.search`}
            aria-label="search-box"
            placeholder="Search"
            type="text"
            onChange={(e) => searchCurrency(e.target.value)}
          />
          <Search tw="w-4 h-full absolute right-[8px] top-0 bottom-0 fill-gray-800" />
        </li>
        {ListItems()}
      </List>
    </div>
  )
}
