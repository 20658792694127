import { FC, useCallback, useMemo, useState } from 'react'

import CurrencySelect from 'components/CurrencySelect'
import Popover from 'components/Popover'
import { getCurrencyIconBySymbol } from 'lib/app-util'
import { Currency } from 'types/currency'

import {
  CurrencyPairDivider,
  CurrencyButton,
  CurrencySelectors,
  CurrencyPairToolbar,
  Wrapper,
  CurrencyBaseIconSpan,
} from './CurrencyPairHeader.styles'

export interface CurrencyPairHeaderProps {
  'data-test': string
  baseCurrency: Currency
  quotedCurrency: Currency
  setBaseCurrency?: (value: Currency) => void
  setQuotedCurrency?: (value: Currency) => void
  readonly?: boolean
}

export const CurrencyPairHeader: FC<CurrencyPairHeaderProps> = ({
  'data-test': dataTest = 'currency-pair-header',
  baseCurrency,
  quotedCurrency,
  setBaseCurrency,
  setQuotedCurrency,
  readonly,
}) => {
  const [showBaseCurrencySelect, setShowBaseCurrencySelect] =
    useState<boolean>(false)

  const [showQuotedCurrencySelect, setShowQuotedCurrencySelect] =
    useState<boolean>(false)

  const [
    baseCurrencyButtonReferenceElement,
    setBaseCurrencyButtonReferenceElement,
  ] = useState<HTMLButtonElement | null>(null)

  const [
    quotedCurrencyButtonReferenceElement,
    setQuotedCurrencyButtonReferenceElement,
  ] = useState<HTMLButtonElement | null>(null)

  const handleDismissBaseCurrenciesSelect = useCallback(() => {
    setShowBaseCurrencySelect(false)
  }, [])

  const handleDismissQuotedCurrenciesSelect = useCallback(() => {
    setShowQuotedCurrencySelect(false)
  }, [])

  const handleBaseCurrencyButtonClick = useCallback(() => {
    setShowQuotedCurrencySelect(false)
    setShowBaseCurrencySelect(true)
  }, [])

  const handleQuotedCurrencyButtonClick = useCallback(() => {
    setShowBaseCurrencySelect(false)
    setShowQuotedCurrencySelect(true)
  }, [])

  const handleBaseCurrencySelect = useCallback(
    (e: { value: Currency }) => {
      setShowBaseCurrencySelect(false)
      setBaseCurrency(e.value)
    },
    [setBaseCurrency],
  )

  const handleQuotedCurrencySelect = useCallback(
    (e: { value: Currency }) => {
      setShowQuotedCurrencySelect(false)
      setQuotedCurrency(e.value)
    },
    [setQuotedCurrency],
  )

  const baseIcon = useMemo(
    () => getCurrencyIconBySymbol(baseCurrency.Symbol),
    [baseCurrency.Symbol],
  )

  return (
    <Wrapper data-test={`${dataTest}.container`}>
      <CurrencyPairToolbar>
        <CurrencySelectors>
          <CurrencyButton
            data-test={`${dataTest}.currency-base`}
            size="small"
            ref={setBaseCurrencyButtonReferenceElement}
            onClick={handleBaseCurrencyButtonClick}
            disabled={readonly}
          >
            {baseIcon ? (
              <CurrencyBaseIconSpan
                data-test={`${dataTest}.currency-base-icon`}
              >
                {baseIcon()}
              </CurrencyBaseIconSpan>
            ) : null}
            <span>{baseCurrency.Symbol}</span>
          </CurrencyButton>
          <CurrencyPairDivider />
          <CurrencyButton
            data-test={`${dataTest}.currency-quoted`}
            size="small"
            ref={setQuotedCurrencyButtonReferenceElement}
            onClick={handleQuotedCurrencyButtonClick}
            disabled={readonly}
          >
            <span>{quotedCurrency.Symbol}</span>
          </CurrencyButton>
        </CurrencySelectors>
      </CurrencyPairToolbar>
      {showBaseCurrencySelect && baseCurrencyButtonReferenceElement ? (
        <Popover
          data-test={`${dataTest}.popover-base`}
          referenceElement={baseCurrencyButtonReferenceElement}
          distance={8.5}
          placement="bottom"
          onDismiss={handleDismissBaseCurrenciesSelect}
        >
          <CurrencySelect
            data-test={`${dataTest}.select-base`}
            onSelectValue={handleBaseCurrencySelect}
          />
        </Popover>
      ) : null}

      {showQuotedCurrencySelect && quotedCurrencyButtonReferenceElement ? (
        <Popover
          data-test={`${dataTest}.popover-quoted`}
          referenceElement={quotedCurrencyButtonReferenceElement}
          distance={8.5}
          placement="bottom"
          onDismiss={handleDismissQuotedCurrenciesSelect}
        >
          <CurrencySelect
            data-test={`${dataTest}.select-quoted`}
            baseCurrency={baseCurrency}
            onSelectValue={handleQuotedCurrencySelect}
          />
        </Popover>
      ) : null}
    </Wrapper>
  )
}
